import React, { FormEvent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, ILabelStyleProps, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from 'office-ui-fabric-react/lib/TextField';
import { Item } from '../../node_modules/react-simple-tree-menu';
import { renameOrgUnit, addOrgUnit, addProgram, addProcessMap, saveOrgStructure } from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react';
import  {OrgNameInput}  from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

interface IState {
  orgUnit: Item;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: Item;
  treeData: IOUStruct[];
  renameOrgUnit(label:string): void;
  addOrgUnit(unit: string, type: string): void;
  addProgram(label: string, type: string): void;
  addProcessMap(label: string, param:{type: string, dragStart(ev: React.DragEvent<HTMLDivElement>):void}): void;
  saveOrgStructure(orStruct: IOUStruct[]): void;
}

class OrgDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: this.props.selOrgUnit,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      onSave: this.saveOrgName,
    }
    this._onChange = this._onChange.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const { label } = this.state.orgUnit;
    const {title, placeholder, dlgLabel} = this.state;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Organization unit details
        </Label>
        <TextField
          label="Label"
          type="text"
          placeholder="Enter label"
          value={label}
          onChange={this._onChange}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>)=>{
            if (ev.key === 'Enter') {
              this.updateLabel();
              ev.preventDefault();
            }
          }}
          onBlur={this.updateLabel}
          styles={textStyles}
        />
        { label &&
          <Stack style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
            <PrimaryButton style={{marginBottom: '10px'}}
              onClick={this.addOrgUnit}>
                Add unit
            </PrimaryButton>
            <PrimaryButton style={{marginBottom: '10px'}}
              onClick={this.addProcessMap}>
                Add process map
            </PrimaryButton>
            <PrimaryButton 
              onClick={this.addProgram}>
                Add program
            </PrimaryButton>
          </Stack>
        }
        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let orgUnit = {...this.state.orgUnit};
    if (newValue) {
      orgUnit.label = newValue;
    }
    this.setState({
      orgUnit,
    })
  }
  updateLabel = () => {
    this.props.renameOrgUnit(this.state.orgUnit.label);
    this.props.saveOrgStructure(this.props.treeData);
  }
  addOrgUnit = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveOrgName,
      title: 'Organization name',
      dlgLabel: "Organization unit name",
      placeholder: "enter organization unit name"
  })
  }
  addProgram = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveProgramName,
      title: 'Program name',
      dlgLabel: "Program name",
      placeholder: "enter program name"
 })
  }
  addProcessMap = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveProcessMapName,
      title: 'Process map name',
      dlgLabel: "Process map name",
      placeholder: "enter map name"
  })
  }

  saveOrgName = (name: string) => {
    this.setState ({
      name,
      showDlg: false,
    })
    this.props.addOrgUnit (name, 'node');
    this.props.saveOrgStructure(this.props.treeData);
  }
  saveProcessMapName = (name: string) => {
    this.setState ({
      showDlg: false,
    })
    this.props.addProcessMap (name, {type: 'map', dragStart: this.onMapDragStart});
    this.props.saveOrgStructure(this.props.treeData);
  }
  saveProgramName = (name: string) => {
    this.setState ({
      showDlg: false,
    })
    this.props.addProgram (name, 'program');
    this.props.saveOrgStructure(this.props.treeData);
  }
  
  onMapDragStart = (ev: React.DragEvent<HTMLDivElement>):void => {
    const obj = ev.currentTarget;
    const data = ev.currentTarget.attributes.getNamedItem("data");
    if (data) {
      ev.dataTransfer.setData("data", data.value);
    }
  
    console.log(data);
      // ev.preventDefault();
  }

  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  renameOrgUnit, addOrgUnit, addProgram, addProcessMap, saveOrgStructure
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(OrgDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
