import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { Item } from 'react-simple-tree-menu';
import { addProgram, addProcessMap, saveOrgStructure } from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import  {OrgNameInput}  from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

interface IState {
  orgUnit: Item;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: Item;
  treeData: IOUStruct[];
  rootType: string;
  addProgram(label: string, type: string): void;
  addProcessMap(label: string, param:{type: string, dragStart(ev: React.DragEvent<HTMLDivElement>):void}): void;
  saveOrgStructure(orStruct: IOUStruct[]): void;
}

class RootDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: {} as Item,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      onSave: this.saveProgramName,
    }
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const {title, placeholder, dlgLabel} = this.state;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Process maps
        </Label>
        {this.props.rootType === 'map root' &&
        <PrimaryButton 
          onClick={this.addProcessMap}>
            Add process map
        </PrimaryButton>
        }
        {this.props.rootType === 'program root' &&
          <PrimaryButton 
            onClick={this.addProgram}>
              Add program
          </PrimaryButton>
        }

        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  addProgram = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveProgramName,
      title: 'Program name',
      dlgLabel: "Program name",
      placeholder: "enter program name"
 })
  }
  addProcessMap = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveProcessMapName,
      title: 'Process map name',
      dlgLabel: "Process map name",
      placeholder: "enter map name"
  })
  }

  saveProcessMapName = (name: string) => {
    this.setState ({
      showDlg: false,
    })
    this.props.addProcessMap (name, {type: 'map', dragStart: this.onMapDragStart});
    this.props.saveOrgStructure(this.props.treeData);
  }
  saveProgramName = (name: string) => {
    this.setState ({
      showDlg: false,
    })
    this.props.addProgram (name, 'program');
    this.props.saveOrgStructure(this.props.treeData);
  }
  onMapDragStart = (ev: React.DragEvent<HTMLDivElement>):void => {
    const obj = ev.currentTarget;
    const data = ev.currentTarget.attributes.getNamedItem("data");
    if (data) {
      ev.dataTransfer.setData("data", data.value);
    }
  
    console.log(data);
      // ev.preventDefault();
  }

  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addProgram, addProcessMap, saveOrgStructure
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(RootDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
