import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";

interface IState {
}
interface IProps {
}

class OrgDetails extends React.PureComponent<IProps, {}, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  public render(): React.ReactNode {
    return(
      <Stack >
        <Label styles={labelStyles}>
          © Powered by avenent.com
        </Label>
      </Stack>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch)

export default connect(
  null, 
  mapDispatchToProps
)(OrgDetails)

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontWeight: "normal", marginLeft:'13px' }
};
