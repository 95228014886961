import React, { FormEvent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, ILabelStyleProps, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from 'office-ui-fabric-react/lib/TextField';
import { Item } from 'react-simple-tree-menu';
import { renameOrgUnit, addProgramMap, saveOrgStructure,
      findDeep, copyProgramMap } from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { OrgNameInput } from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

interface IState {
  orgUnit: Item;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: Item;
  selProcMap: Item;
  treeData: IOUStruct[];
  renameOrgUnit(label:string): void;
  addProgramMap(label: string, type: string): void;
  saveOrgStructure(treeData: IOUStruct[]): void;
  copyProgramMap(map: IOUStruct): void;
}

class ProgTeamDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: this.props.selOrgUnit,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      onSave: this.cancelDlg,
    }
    this._onChange = this._onChange.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const { label } = this.state.orgUnit;
    const {title, placeholder, dlgLabel} = this.state;
    const selMap = this.props.selProcMap && this.props.selProcMap.key;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Program team details
        </Label>
        <TextField
          label="Label"
          type="text"
          placeholder="Enter label"
          value={label}
          onChange={this._onChange}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>)=>{
            if (ev.key === 'Enter') {
              this.updateLabel();
              ev.preventDefault();
            }
          }}
          onBlur={this.updateLabel}
          styles={textStyles}
        />
        { selMap && 
          <Stack horizontal={true} tokens={{childrenGap: 13}} style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
            <PrimaryButton 
              onClick={this.addProgMap}>
              Add program map
            </PrimaryButton>
          </Stack>
        }
        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let orgUnit = {...this.state.orgUnit};
    if (newValue) {
      orgUnit.label = newValue;
    }
    this.setState({
      orgUnit,
    })
  }
  updateLabel = () => {
    this.props.renameOrgUnit(this.state.orgUnit.label);
    this.props.saveOrgStructure(this.props.treeData);
  }
  addProgMap = () => {
    const map = JSON.parse(this.props.selProcMap.data);
    const org = JSON.parse(this.props.selOrgUnit.data);
    if (map.parent === org.parent) {
      const name  = window.prompt('Program map name', '');
      if (name) {
        this.props.addProgramMap (name, 'program map');
        const progTeam = findDeep(this.props.treeData, org.key, undefined);
        if (progTeam) {
          const progMap = (progTeam.nodes as IOUStruct[]).slice(-1)[0];
          console.log(progMap);
          this.props.copyProgramMap(progMap);
        }
        this.props.saveOrgStructure(this.props.treeData);
      }
      // this.setState ({
      //   showDlg: true,
      //   onSave: this.saveProgMap,
      //   title: 'Program map name',
      //   dlgLabel: "Program map name",
      //   placeholder: "enter program map name"
      // })
    } else {
      window.alert('You can only add process maps from the current organization unit');
    }
  }

  saveProgMap = (name: string) => {
    this.setState ({
      name,
      showDlg: false,
    })
    this.props.addProgramMap (name, 'program map');
    this.props.saveOrgStructure(this.props.treeData);
  }

  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  selProcMap: state.orgStructure.selectedMap,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  renameOrgUnit, addProgramMap, saveOrgStructure, copyProgramMap
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ProgTeamDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
