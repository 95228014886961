import React from 'react';
import * as Fabric from "office-ui-fabric-react";
import './App.css';
import Home from '../src/modules/home'

const {
  Stack,
  Label,
} = Fabric;

function App() {
  return (
    <div className="App">
      <header className="App-header" >
      <Stack>
      <Label style={{marginLeft: "10px", fontSize:'24px', color: '#000'}}>
          Smart SOP
        </Label>
        <Label style={{marginLeft: '10px', fontSize: '18px', fontWeight: 'normal', marginTop: '-10px'}}>
          Program management
        </Label>
        <Home />
      </Stack>
      </header>
      <main>
        {/* <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={About} /> */}
      </main>
    </div>
  );
}

export default App;
