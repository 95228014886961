import React, { FormEvent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, ILabelStyleProps, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from 'office-ui-fabric-react/lib/TextField';
import { Item } from '../../node_modules/react-simple-tree-menu';
import { renameOrgUnit, saveOrgStructure, launchProgramMap, copyProgramMap } from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react';
import { OrgNameInput } from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

const mapUri = require('../config/keys.js').mapURI;

interface IState {
  orgUnit: IOUStruct;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: IOUStruct;
  selProcMap: Item;
  treeData: IOUStruct[];
  renameOrgUnit(label:string): void;
  saveOrgStructure(orStruct: IOUStruct[]): void;
  launchProgramMap(key: string): void;
  copyProgramMap(prgMap: IOUStruct): void;
}

class ProgramMapDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: this.props.selOrgUnit,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      onSave: this.cancelDlg,
    }
    this._onChange = this._onChange.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const { label, sourceMap, mapLaunched } = this.state.orgUnit;
    const {title, placeholder, dlgLabel} = this.state;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Program map details
        </Label>
        <TextField
          label="Label"
          type="text"
          placeholder="Enter label"
          value={label}
          onChange={this._onChange}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>)=>{
            if (ev.key === 'Enter') {
              this.updateLabel();
              ev.preventDefault();
            }
          }}
          onBlur={this.updateLabel}
          styles={textStyles}
        />
        <Label>
          Source map: {sourceMap && sourceMap.label}
        </Label>
        { label &&
          <Stack style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
            <PrimaryButton style={{marginBottom: '10px'}}
              onClick={this.openProgMap}>
                Open program map
            </PrimaryButton>
            <PrimaryButton style={{marginBottom: '10px'}}
              onClick={this.updateProgMap}>
                Update program map
            </PrimaryButton>
            {!mapLaunched &&
              <PrimaryButton onClick={this.launchProgramMap}>
                Launch map for execution
              </PrimaryButton>
            }
          </Stack>
        }
        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let orgUnit = {...this.state.orgUnit};
    if (newValue) {
      orgUnit.label = newValue;
    }
    this.setState({
      orgUnit,
    })
  }
  updateLabel = () => {
    this.props.renameOrgUnit(this.state.orgUnit.label);
    this.props.saveOrgStructure(this.props.treeData);
  }

  openProgMap = () => {
    const key = this.props.selOrgUnit.key.split('/').pop();
    const launched = this.props.selOrgUnit.mapLaunched;
    const query = {mapid: key,
      label: this.props.selOrgUnit.label,
      programMode:1,
      mapLaunched:1,
    }
    // const params = JSON.stringify (query) as string;
    const params = btoa(JSON.stringify(query));
    const win = window.open(`${mapUri}/?params=${params}`, 'id=ProcessMap');

    // const win = window.open(`${mapUri}/?mapid=${key}&label=${this.props.selOrgUnit.label}&programMode=1${launched?`&mapLaunched=1`:''}`, 'id=ProcessMap');
    if (win) {
      win.focus();
    }
  }
  updateProgMap = () => {
    const res = window.confirm('You are likely to loose the data from execution.\nWould you like to continue');
    if (res) {
      this.props.copyProgramMap(this.props.selOrgUnit);
    }
  }
  launchProgramMap = () => {
    this.props.launchProgramMap(this.state.orgUnit.key);
    this.props.saveOrgStructure(this.props.treeData);
  }
  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  selProcMap: state.orgStructure.selectedMap,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  renameOrgUnit, saveOrgStructure, launchProgramMap, copyProgramMap
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ProgramMapDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
