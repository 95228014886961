import React, { FormEvent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, ILabelStyleProps, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from 'office-ui-fabric-react/lib/TextField';
import { Item } from '../../node_modules/react-simple-tree-menu';
import { renameOrgUnit, selectProcMap, removeProcMap, saveOrgStructure } from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react';
import { OrgNameInput } from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

const mapUri = require('../config/keys.js').mapURI;

interface IState {
  orgUnit: Item;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: Item;
  selProcMap: Item;
  treeData: IOUStruct[];
  renameOrgUnit(label:string): void;
  selectProcMap(): void;
  removeProcMap(): void;
  saveOrgStructure(orStruct: IOUStruct[]): void;
}

class MapDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: this.props.selOrgUnit,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      onSave: this.cancelDlg,
    }
    this._onChange = this._onChange.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const { label } = this.state.orgUnit;
    const {title, placeholder, dlgLabel} = this.state;
    if (this.props.selProcMap.key && (this.state.orgUnit.key !== this.props.selProcMap.key)) {
      this.removeProcMap();
    }
    const selMap = this.props.selProcMap && this.props.selProcMap.key;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Process map details
        </Label>
        <TextField
          label="Label"
          type="text"
          placeholder="Enter label"
          value={label}
          onChange={this._onChange}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>)=>{
            if (ev.key === 'Enter') {
              this.updateLabel();
              ev.preventDefault();
            }
          }}
          onBlur={this.updateLabel}
          styles={textStyles}
        />
        { label &&
          <Stack>
            <Stack horizontal={true} tokens={{childrenGap: 5}} style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
              <PrimaryButton 
                onClick={this.openProcMap}>
                  Open map
              </PrimaryButton>
              <PrimaryButton 
                onClick={this.editProcMap}>
                  Edit map
              </PrimaryButton>
            </Stack >
            <Stack horizontal={true} tokens={{childrenGap: 5}} style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
              <PrimaryButton 
                onClick={this.selectProcMap}>
                Select map
              </PrimaryButton>
              { selMap &&
                <PrimaryButton 
                  onClick={this.removeProcMap}>
                  Remove map
                </PrimaryButton>
              }
            </Stack>
          </Stack>
        }
        <Label styles={labelStyles}>
          Note: You can drag the flow map to a program team for execution
        </Label>
        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let orgUnit = {...this.state.orgUnit};
    if (newValue) {
      orgUnit.label = newValue;
    }
    this.setState({
      orgUnit,
    })
  }
  updateLabel = () => {
    this.props.renameOrgUnit(this.state.orgUnit.label);
    this.props.saveOrgStructure(this.props.treeData);
  }

  openProcMap = () => {
    const key = this.props.selOrgUnit.key.split('/').pop();
    const query = {
      mapid: key,
      label: this.props.selOrgUnit.label,
    }
    const params = btoa(JSON.stringify(query));
    const win = window.open(`${mapUri}/?params=${params}`, 'id=ProcessMap');

    if (win) {
      win.focus();
    }
  }
  editProcMap = () => {
    const key = this.props.selOrgUnit.key.split('/').pop();
    const query = {mapid: key,
      label: this.props.selOrgUnit.label,
      editMode: true,
    }
    // const params = JSON.stringify (query) as string;
    const params = btoa(JSON.stringify(query));
    const win = window.open(`${mapUri}/?params=${params}`, 'id=ProcessMap');

    // const win = window.open(`${mapUri}/?mapid=${key}&label=${this.props.selOrgUnit.label}&editMode=1`, 'id=ProcessMap');
    if (win) {
      win.focus();
    }
  }

  selectProcMap = () => {
    this.props.selectProcMap();
    alert("Process map selected for adding to a program team\nSelect a program team and add the map")
  }
  removeProcMap = () => {
    this.props.removeProcMap();
    alert("Selected process map removed")
  }
  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  selProcMap: state.orgStructure.selectedMap,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  renameOrgUnit, selectProcMap, removeProcMap, saveOrgStructure
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(MapDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
