import React, { FormEvent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, ILabelStyleProps, Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from 'office-ui-fabric-react/lib/TextField';
import { Item } from 'react-simple-tree-menu';
import { renameOrgUnit, addProgramTeam, dragProgramMap, saveOrgStructure , 
        findDeep, copyProgramMap} from '../actions/orgActions';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { OrgNameInput } from './orgNameInputDialog';
import { IOUStruct } from '../actions/types';

interface IState {
  orgUnit: Item;
  showDlg: boolean;
  name: string;
  title: string;
  dlgLabel: string;
  placeholder: string;
  sourceKey: string;
  targetKey: string;
  onSave(name: string): void;
}

interface IProps {
  selOrgUnit: Item;
  treeData: IOUStruct[];
  renameOrgUnit(label:string): void;
  addProgramTeam(label: string, type: string, drop: (ev: React.DragEvent<HTMLDivElement>)=>void): void;
  dragProgramMap(label: string, source: string, dest: string): void;
  saveOrgStructure(treeData: IOUStruct[]): void;
  copyProgramMap(map: IOUStruct): void;
}

class ProgramDetails extends React.PureComponent<IProps, IState > {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgUnit: this.props.selOrgUnit,
      showDlg: false,
      name: '',
      title: '',
      dlgLabel: '',
      placeholder: '',
      sourceKey: '',
      targetKey: '',
      onSave: this.saveProgTeam,
    }
    this._onChange = this._onChange.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
  }
  public componentDidMount(): void {
    const orgUnit = this.props.selOrgUnit;
    this.setState({
      orgUnit,
    });
  }
  public componentDidUpdate(): void {
    const orgUnit = this.props.selOrgUnit;
    if (orgUnit.key !== this.state.orgUnit.key) {
      this.setState({
        orgUnit,
      });  
    }
  }

  public render(): React.ReactNode {
    const { label } = this.state.orgUnit;
    const {title, placeholder, dlgLabel} = this.state;
    return(
      <Stack >
        <Label styles={headerStyles}>
          Program details
        </Label>
        <TextField
          label="Label"
          type="text"
          placeholder="Enter label"
          value={label}
          onChange={this._onChange}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>)=>{
            if (ev.key === 'Enter') {
              this.updateLabel();
              ev.preventDefault();
            }
          }}
          onBlur={this.updateLabel}
          styles={textStyles}
        />
        { label &&
          <Stack horizontal={true} tokens={{childrenGap: 13}} style={{marginLeft: '5px', marginRight: '5px', marginTop: '5px'}}>
            <PrimaryButton 
              onClick={this.addProgTeam}>
                Add Teams
            </PrimaryButton>
          </Stack>
        }
        <OrgNameInput 
          showDlg={this.state.showDlg} 
          title={title}
          label={dlgLabel}
          placeholder={placeholder}
          onSaveDlg={this.state.onSave}
          onCancelDlg={this.cancelDlg}
        />
      </Stack>
    )
  }
  _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    let orgUnit = {...this.state.orgUnit};
    if (newValue) {
      orgUnit.label = newValue;
    }
    this.setState({
      orgUnit,
    })
  }
  updateLabel = () => {
    this.props.renameOrgUnit(this.state.orgUnit.label);
    this.props.saveOrgStructure(this.props.treeData);
  }
  addProgTeam = () => {
    this.setState ({
      showDlg: true,
      onSave: this.saveProgTeam,
      title: 'Program team name',
      dlgLabel: "Program team name",
      placeholder: "enter program team name"
    })
  }

  saveProgTeam = (name: string) => {
    this.setState ({
      name,
      showDlg: false,
    })
    this.props.addProgramTeam (name, 'team', this.mapDrop);
    this.props.saveOrgStructure(this.props.treeData);
  }

  saveProgramMap = (name: string) => {
    this.setState({
      showDlg: false,
    })
    this.props.dragProgramMap(name, this.state.sourceKey, this.state.targetKey);
  }

  mapDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    const obj = ev.currentTarget.attributes.getNamedItem("data");
    if (obj) {
      const target = JSON.parse(obj.value);
      const source = ev.dataTransfer.getData("data");
      if (source) {
        const data = JSON.parse(source);
        if (target.parent === data.parent) {
          const name  = window.prompt('Program map name', '');
          if (name) {
            this.props.dragProgramMap(name, data.key, target.key);
            const progTeam = findDeep(this.props.treeData, target.key, undefined);
            if (progTeam) {
              const progMap = (progTeam.nodes as IOUStruct[]).slice(-1)[0];
              console.log(progMap);
              this.props.copyProgramMap(progMap);
            }
            this.props.saveOrgStructure(this.props.treeData);
          }
        } else {
          window.alert('You can only add process maps from the current organization unit');
        }
      }
    }
  }

  cancelDlg = () => {
    this.setState ({
      showDlg: false,
    })  
  }
}

const mapStateToProps = (state: any) => ({
  selOrgUnit: state.orgStructure.selOrgUnit,
  treeData: state.orgStructure.treeData,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  renameOrgUnit, addProgramTeam, dragProgramMap, saveOrgStructure, copyProgramMap
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ProgramDetails)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};
const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
