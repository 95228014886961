import { SEL_ORG_UNIT, REN_ORG_LABEL, ADD_ORG_UNIT, 
        ADD_PROGRAM, ADD_PROGRAM_TEAM, SEL_PROC_MAP, REMOVE_PROC_MAP,
        ADD_PROGRAM_MAP, ADD_PROC_MAP, DRAG_PROGRAM_MAP, GET_ORG_STRUCTURE, 
        LAUNCH_PROGRAM_MAP, IOUStruct } from './types';
import { Dispatch } from 'redux';
import { Item } from 'react-simple-tree-menu';
import axios from 'axios';

const dbUri = require('../config/keys.js').mongoURI;
const secret = require('../config/keys.js').secretOrKey;

export const selectedOrgUnit = (e: Item) => (dispatch: Dispatch) => {
  dispatch ({
    type: SEL_ORG_UNIT,
    payload: e
  });
}
export const getOrgStructure = (mapDrop: any) => async(dispatch: Dispatch)=>{
  const params={secret: secret, orgName: 'avenent'};
  await axios.get (dbUri+'/getOrgStructure', {params})
  .then ( data => {
      dispatch ({
        type: GET_ORG_STRUCTURE,
        payload: {data: data.data, drop: mapDrop}
      })
  })
  .catch (err => {
    console.log (err);
    window.alert(err);
  })
}
export const saveOrgStructure = (orgStruct: IOUStruct[]) => async(dispatch: Dispatch)=>{
  const params={secret: secret};
  const org = {
    orgName: 'avenent',
    orgStruct: orgStruct
  };
  await axios.post (dbUri+'/saveOrgStructure', org, {params})
  .then ( (data: any) => {
  })
  .catch (err => {
    console.log (err);
  })
}
export const renameOrgUnit = (label: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: REN_ORG_LABEL,
    payload: label
  });
}
export const addOrgUnit = (label: string, type: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_ORG_UNIT,
    payload: {label: label, type: type}
  });
}
export const addProgram = (label: string, type: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_PROGRAM,
    payload: {label: label, type: type}
  });
}
export const addProcessMap = (label: string, param: {type: string, dragStart:(ev: React.DragEvent<HTMLDivElement>)=>void}) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_PROC_MAP,
    payload: {label: label, type: param.type, dragStart: param.dragStart}
  });
}

export const addMap = (label: string, type: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_PROGRAM,
    payload: {label: label, type: type}
  });
}
export const addProgramTeam = (label: string, type: string, drop:(ev: React.DragEvent<HTMLDivElement>)=>void) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_PROGRAM_TEAM,
    payload: {label: label, type: type, drop:drop}
  });
}

export const selectProcMap = () => (dispatch: Dispatch) => {
  dispatch ({
    type: SEL_PROC_MAP,
    payload: {}
  });
}
export const removeProcMap = () => (dispatch: Dispatch) => {
  dispatch ({
    type: REMOVE_PROC_MAP,
    payload: {}
  });
}
export const addProgramMap = (label: string, type: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: ADD_PROGRAM_MAP,
    payload: {label: label, type: type}
  });
}
export const dragProgramMap = (label: string, source: string, dest: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: DRAG_PROGRAM_MAP,
    payload: {label: label, source: source, dest: dest, type: 'program map'}
  });
}
export const copyProgramMap = (prgMap: IOUStruct) => async(dispatch: Dispatch)=>{
  const params={secret: secret};
  const map = {
    prgMapName: prgMap.label,
    targetKey: prgMap.key.split('/').pop(),
    sourceKey: prgMap.sourceMap  && prgMap.sourceMap.key,
  };
  await axios.post (dbUri+'/addProgramMap', map, {params})
  .then ( (data: any) => {
    console.log(data.data);
    window.alert('Program map updated');
  })
  .catch (err => {
    console.log (err);
    window.alert('Error: '+ err);

  })
}
export const launchProgramMap = (mapKey: string) => (dispatch: Dispatch) => {
  dispatch ({
    type: LAUNCH_PROGRAM_MAP,
    payload: mapKey
  });
}

export const findDeep = (data: IOUStruct[] | undefined, key: string, org: IOUStruct|undefined): IOUStruct|undefined => {
  if (org) return org;
  if (data) {
    org = data.find((item)=> item.key === key);
    if (org) {
      return org;
    }
    data.forEach(item => {
      if(item.nodes) {
        org = findDeep(item.nodes, key, org);
        if (org) {
          return org;
        }
      }      
    });
  }
  return org;
};

