import {
  SEL_ORG_UNIT, REN_ORG_LABEL, ADD_ORG_UNIT, ADD_PROGRAM, ADD_PROGRAM_TEAM,
  SEL_PROC_MAP, REMOVE_PROC_MAP, ADD_PROGRAM_MAP, DRAG_PROGRAM_MAP, GET_ORG_STRUCTURE,
  LAUNCH_PROGRAM_MAP
} from '../actions/types';
import React from 'react';
import { IOUStruct, ADD_PROC_MAP } from '../actions/types';
import { Item } from '../../node_modules/react-simple-tree-menu';
import {findDeep} from '../actions/orgActions';

const emptyOrg: Item = {
  hasNodes: false,
  isOpen: false,
  level: 0,
  key: '',
  label: '',
  // [name: string]: any,
};
const orgStuct = [{
  key: 'root',
  label: 'Root organization',
  parent: '',
  level: {$numberInt: "0"},
  type: 'node',
}];
const initialOrgState = {
  treeData: [
  {
    key: 'root',
    label: 'Root organization',
    parent: '',
    level: 0,
    type: 'node',
    nodes: [
      {
        key: 'prog-11',
        label: 'Programs',
        parent: 'root',
        level: 1,
        type: 'program root',
        nodes: [{
          key: 'prog-111',
          label: 'Program 1',
          parent: 'prog-11',
          level: 2,
          type: 'program',  
        },
        {
          key: 'prog-112',
          label: 'Program 2',
          parent: 'prog-11',
          level: 2,
          type: 'program',
        }]
      },
      {
        key: 'key-11',
        label: 'Org unit 1',
        parent: 'root',
        level: 1,
        type: 'node',
      },
      {
        key: 'key-12',
        label: 'Org unit 2',
        parent: 'root',
        level: 1,
        type: 'node',
        nodes: [
          {
            key: 'key-21',
            label: 'Org unit 21',
            parent: 'level12',
            level: 2,
            type: 'node',
          },
        ]
      }
    ],
  }
  ],
  selOrgUnit:  emptyOrg,
  selectedMap: emptyOrg,
};

export const orgStructure = (state = initialOrgState, action: {type: string, payload: any}) => {
  switch (action.type) {
    case SEL_ORG_UNIT: {
      return {
        ...state,
        selOrgUnit: action.payload,
      };
    }
    case GET_ORG_STRUCTURE: {
      let struct = orgStuct;
      if (action.payload.data) {
        struct = action.payload.data.orgStruct;
      }
      convertLevel(struct, action.payload.drop);
      return {
        ...state,
        treeData: struct,
      }
    }
    case REN_ORG_LABEL: {
      const keyArr = {...state.selOrgUnit}.key.split(('/'));
      const key = keyArr.pop();
      console.log(key);
      const trData = [...state.treeData];
      if (key) {
        const org = findDeep(trData, key, undefined);
        if (org) {
          org.label = action.payload;
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    }
    case ADD_ORG_UNIT: {
      const key = {...state.selOrgUnit}.key.split(('/')).pop();
      const trData = [...state.treeData];
      let org;
      if (key) {
        org = findDeep(trData, key, undefined);
        if (org && org.type === 'node') {
          if (org.nodes) {
            org.nodes.push ({
              label: action.payload.label,
              key: getNewOrgKey(),
              parent: org.parent,
              level: (org.level as number) + 1,
              type: action.payload.type,
            })
          } else {
            org.nodes = [{
              label: action.payload.label,
              key: getNewOrgKey(),
              parent: org.key,
              level: (org.level as number) + 1,
              type: action.payload.type,
            }]
          }
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    }
    case ADD_PROGRAM: {
      const key = {...state.selOrgUnit}.key.split(('/')).pop();
      const trData = [...state.treeData];
      let org;
      if (key) {
        org = findDeep(trData, key, undefined);
        if (org) {
          let root: IOUStruct;
          if (org.type === 'node') {
                // returns a prg root node if one exists or 
                // creates and return if one does not exist
              root = getProgramRoot (org, action.payload.type);
              console.log (root);
          } else if (org.type === 'program root') {
            root = org;
          } else {
            root = org;
          }
          if (action.payload.type === 'program' && root.type !== 'program root') {
            return state;
          }
          if (action.payload.type === 'map' && root.type !== 'map root') {
            return state;
          }
          if (root.nodes) {
            root.nodes.push ({
              label: action.payload.label,
              key: getNewOrgKey(),
              parent: root.parent,
              level: (root.level as number) + 1,
              type: action.payload.type,
            })
          } else {
            root.nodes = [{
              label: action.payload.label,
              key: getNewOrgKey(),
              parent: root.key,
              level: (root.level as number) + 1,
              type: action.payload.type,
            }]
          }
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    }
    case ADD_PROC_MAP: {
      const keys = {...state.selOrgUnit}.key.split(('/'));
      const key = keys.pop();
      const mapParent = keys.pop();
      const trData = [...state.treeData];
      let org;
      if (key) {
        org = findDeep(trData, key, undefined);
        if (org) {
          let root: IOUStruct;
          if (org.type === 'node') {
                // returns a prg root node if one exists or 
                // creates and return if one does not exist
              root = getProgramRoot (org, action.payload.type);
          } else if (org.type === 'map root') {
            root = org;
          } else {
            root = org;
          }
          if (action.payload.type === 'map' && root.type !== 'map root') {
            return state;
          }
          const nodeKey = getNewOrgKey();
          if (root.nodes) {
            root.nodes.push ({
              label: action.payload.label,
              key: nodeKey,
              parent: root.parent,
              level: (root.level as number) + 1,
              type: action.payload.type,
              draggable: true,
              onDragStart: action.payload.dragStart,
              data: JSON.stringify({key: nodeKey, parent: root.parent}),
            })
          } else {
            root.nodes = [{
              label: action.payload.label,
              key: nodeKey,
              parent: root.key,
              level: (root.level as number) + 1,
              type: action.payload.type,
              draggable: true,
              onDragStart: action.payload.dragStart,
              data: JSON.stringify({key: nodeKey, parent: root.parent}),
            }]
          }
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    } 
    case ADD_PROGRAM_TEAM: {
      const keys = {...state.selOrgUnit}.key.split(('/'));
      const key = keys.pop();
      keys.pop();
      const prgParent = keys.pop();
      const trData = [...state.treeData];
      let org;
      if (key) {
        org = findDeep(trData, key, undefined);
        if (org) {
          if (org.type === 'program') {
            const nodeKey = getNewOrgKey();
            if (org.nodes) {
              org.nodes.push ({
                label: action.payload.label,
                key: nodeKey,
                parent: org.parent,
                level: (org.level as number) + 1,
                type: action.payload.type,
                onDragOver: mapDragOver,
                onDragLeave: mapDragLeave,
                onDrop: action.payload.drop,
                data: JSON.stringify({key: nodeKey, parent: prgParent}),
              })
            } else {
              org.nodes = [{
                label: action.payload.label,
                key: nodeKey,
                parent: org.key,
                level: (org.level as number) + 1,
                type: action.payload.type,    
                onDragOver: mapDragOver,
                onDragLeave: mapDragLeave,
                onDrop: action.payload.drop,
                data: JSON.stringify({key: nodeKey, parent: prgParent}),
              }]
            }
          }
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    }
    case SEL_PROC_MAP: {
      const org = state.selOrgUnit;
      if (org.type === 'map') {
        return ({
          ...state,
          selectedMap: org
        })
      }
      break;
    }
    case REMOVE_PROC_MAP: {
      if (state.selectedMap && state.selectedMap.key) {
        return ({
          ...state,
          selectedMap: emptyOrg
        })
      }
      break;
    }
    case ADD_PROGRAM_MAP: {
      if (state.selectedMap.key) {
        const key = {...state.selOrgUnit}.key.split(('/')).pop();
        const trData = [...state.treeData];
        if (key) {
          const org = findDeep(trData, key, undefined);
          if (org) {
            if (org.type === 'team') {
              const srcKey = state.selectedMap.key.split('/').pop();
              const source = findDeep(trData, srcKey as string, undefined);
              if (org.nodes) {
                org.nodes.push ({
                  label: action.payload.label,
                  key: getNewOrgKey(),
                  parent: org.parent,
                  level: (org.level as number) + 1,
                  type: action.payload.type,
                  sourceMap: source,
                  mapLaunched: false,
                })
              } else {
                org.nodes = [{
                  label: action.payload.label,
                  key: getNewOrgKey(),
                  parent: org.key,
                  level: (org.level as number) + 1,
                  type: action.payload.type,
                  sourceMap: source,
                  mapLaunched: false,
                }]
              }
            }
          }
        }
        return ({
          ...state,
          treeData: trData
        });
      }
      break;
    }
    case DRAG_PROGRAM_MAP: {
      const trData = [...state.treeData];
      const target = action.payload.dest ? findDeep(trData, action.payload.dest, undefined) : null;
      const source = action.payload.source ? findDeep(trData, action.payload.source, undefined) : null;
      if (source && source.type === 'map' && target && target.type === 'team') {
        if (target.nodes) {
          target.nodes.push({
            label: action.payload.label,
            key: getNewOrgKey(),
            parent: target.parent,
            level: (target.level as number) + 1,
            type: action.payload.type,
            sourceMap: source,
            mapLaunched: false,
          })
        } else {
          target.nodes = [{
            label: action.payload.label,
            key: getNewOrgKey(),
            parent: target.key,
            level: (target.level as number) + 1,
            type: action.payload.type,
            sourceMap: source,
            mapLaunched: false,
          }]
        }
      }
      return ({
        ...state,
        treeData: trData
      });
    }
    case LAUNCH_PROGRAM_MAP: {
      const trData = [...state.treeData];
      const mapKey = action.payload.split('/').pop();
      const map = findDeep(trData, mapKey, undefined);
      if (map) {
        map.mapLaunched = true;
      }
      return ({
        ...state,
        treeData: trData,
        selOrgUnit: map,
      });
    }
    default:
      return state;
  }
}

// export const findDeep = (data: IOUStruct[] | undefined, key: string, org: IOUStruct|undefined): IOUStruct|undefined => {
//   if (org) return org;
//   if (data) {
//     org = data.find((item)=> item.key === key);
//     if (org) {
//       return org;
//     }
//     data.forEach(item => {
//       if(item.nodes) {
//         org = findDeep(item.nodes, key, org);
//         if (org) {
//           return org;
//         }
//       }      
//     });
//   }
//   return org;
// };

const convertLevel = (data: IOUStruct[], drop: any) => {
  data.forEach(item => {
    if (item.nodes) {
      convertLevel (item.nodes, drop);
    }
    item.level = Number((item.level as {$numberInt: string}).$numberInt);
    if (item.type === 'map') {
      item.onDragStart = onMapDragStart;
    }
    if (item.type === 'team') {
      item.onDragOver = mapDragOver;
      item.onDragLeave = mapDragLeave;
      item.onDrop = drop;
    }
  })
}
const getNewOrgKey = (): string => {
  return 'key-' + Math.random().toString(16).slice(7);
}
const getProgramRoot = (org: IOUStruct, type: string) : IOUStruct => {
  let rootType = (type === 'program') ? 'program root' : (type === 'map') ? 'map root' : 'node'
  let rootLabel = (type === 'program') ? 'Programs' : (type === 'map') ? 'Process Maps' : 'Nodes'
  const prgRoot = {
    label: rootLabel,
    key: getNewOrgKey(),
    level: (org.level as number) + 1,
    parent: org.key,
    type: rootType,
  };
  if (org.nodes) {
    const node = org.nodes.find ((item) => item.type === rootType);
    if (node) { // check if root type exist
      return node;
    } else {
      if (rootType === 'map root') {
        // add to the beginning of the array
        org.nodes.unshift (prgRoot);
      }
      if (rootType === 'program root') {
        // check if program root exits
        const rootNode = org.nodes.find ((item) => item.type === 'map root');
        if (rootNode) {
          // insert after program root node
          org.nodes.splice (1, 0, prgRoot);
        } else {
            // program root node does not exist. add to the top of the array
          org.nodes.unshift (prgRoot);
        }
      }
      return prgRoot;
    }
  } else {
    org.nodes = [prgRoot];
    return prgRoot;
  }
}
const onMapDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
  const obj = ev.currentTarget;
  const data = ev.currentTarget.attributes.getNamedItem("data");
  if (data) {
    ev.dataTransfer.setData("data", data.value);
  }

  console.log(data);
  // ev.preventDefault();
}
const mapDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
  const obj = ev.target;
  ev.preventDefault();
}
const mapDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
  const obj = ev.target;
  ev.preventDefault();
}

