import React, { FormEvent } from "react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { ILabelStyles, ILabelStyleProps } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ITextFieldStyles, TextField, ITextFieldStyleProps } from "office-ui-fabric-react/lib/TextField";

export interface IProps {
  showDlg: boolean;
  title: string;
  label: string;
  placeholder: string;
  onSaveDlg(name: string): void;
  onCancelDlg(): void;
}
export interface IState {
  showDlg: boolean;
  title: string;
  label: string;
  placeholder: string;
  name: string;
}
export class OrgNameInput extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super (props);
    this.state = {
      showDlg: this.props.showDlg,
      title: "",
      label: '',
      placeholder: '',
      name: '',
    };
  }
  componentDidMount() {
    this.setState ({
      showDlg: this.props.showDlg,
    });
  }
  componentDidUpdate () {
    if (this.state.showDlg !== this.props.showDlg) {
      this.setState ({
        showDlg: this.props.showDlg,
        title: this.props.title,
        label: this.props.label,
        placeholder: this.props.placeholder,
        name: '',
      });
    }
  }
  public render(): React.ReactNode {
    const { showDlg, title , name, label, placeholder} = this.state;
    return (
      <Stack>
        <Dialog
          hidden={!showDlg}
          onDismiss={this.props.onCancelDlg}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: title,
            // title: 'Add a new organization unit',
          }}
        >
          <TextField
            type="text"
            label={label}
            placeholder={placeholder}
            value={name}
            required={true}
            onChange={this._onChangeName}
            styles={textStyles}
          />
          <DialogFooter>
            <PrimaryButton
                onClick={this.onSave}
                text="Save"
            />
            <DefaultButton
              onClick={this.onCancel}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      </Stack>
    )
  }
  _onChangeName = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      this.setState({
        name: newValue || ''
      });
  }
  private onCancel = () => {
    this.setState ({
      showDlg: false,
    });
    this.props.onCancelDlg();
  }
  private onSave = () => {
    this.setState ({
      showDlg: false,
    });
    this.props.onSaveDlg(this.state.name);
  }
}

const labelStyles = (props: ILabelStyleProps): ILabelStyles => {
  return {
  root: { fontWeight: "normal", marginLeft: '5px' }
  };
};
const textStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
  fieldGroup: [
    {marginLeft: '5px', marginRight: '5px'}
  ],
    subComponentStyles: {
      label: labelStyles 
    }
  }
};
