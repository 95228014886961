export const SEL_ORG_UNIT = 'SEL_ORG_UNIT';
export const REN_ORG_LABEL = 'REN_ORG_LABEL';
export const ADD_ORG_UNIT = 'ADD_ORG_UNIT';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const ADD_PROC_MAP = 'ADD_PROC_MAP';
export const ADD_MAP = 'ADD_MAP';
export const ADD_PROGRAM_TEAM = 'ADD_PROGRAM_TEAM';
export const SEL_PROC_MAP = 'SEL_PROC_MAP';
export const REMOVE_PROC_MAP = 'REMOVE_PROC_MAP';
export const ADD_PROGRAM_MAP = 'ADD_PROGRAM_MAP';
export const DRAG_PROGRAM_MAP = 'DRAG_PROGRAM_MAP';
export const GET_ORG_STRUCTURE = 'GET_ORG_STRUCTURE';
export const LAUNCH_PROGRAM_MAP = 'LAUNCH_PROGRAM_MAP';

export interface IOUStruct {
  key: string;
  label: string;
  parent: string;
  level: number | {$numberInt: string}; // tree level
  type: string; // node type 'node', 'map', 'program', 'team', 'program map'
  draggable?: boolean; // to be set for process maps to be dragged to a program team
  onDragStart?(ev: React.DragEvent<HTMLDivElement>): void;
  onDragOver?(ev: React.DragEvent<HTMLDivElement>): void;
  onDrop?(ev: React.DragEvent<HTMLDivElement>): void;
  onDragLeave?(ev: React.DragEvent<HTMLDivElement>): void;
  nodes?: IOUStruct[];
  data?: any; // store node key for drag drop operation
  mapID?: string; // database ID for the map, used for loading and saving the maps
  sourceMap?: IOUStruct; // source map key to used for program maps
  mapLaunched?: boolean; // flag set when the map is launched for execution
};

