import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ILabelStyles, Label } from "office-ui-fabric-react/lib/Label";
import { Stack, StackItem, IStackItemStyles } from "office-ui-fabric-react/lib/Stack";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import TreeMenu, {Item} from 'react-simple-tree-menu';
import '../../node_modules/react-simple-tree-menu/dist/main.css';
import OrgDetails from './orgDetails';
import OrgFooter from './orgFooter';
import { IOUStruct } from '../actions/types';
import { selectedOrgUnit, getOrgStructure, dragProgramMap, saveOrgStructure, 
      findDeep, copyProgramMap } from '../actions/orgActions';
import ProgramDetails from './programDetails';
import MapDetails from './mapDetails';
import ProgTeamDetails from './prgTeamsDetails';
import RootDetails from './mapRootDetails';
import ProgramMapDetails from './programMapDetails';

interface IProps {
  selOrgUnit: Item;
  treeData: IOUStruct[];
  selectedOrgUnit(e: Item): void;
  getOrgStructure(mapDrop: any): void;
  dragProgramMap(label: string, source: string, dest: string): void;
  saveOrgStructure(treeData: IOUStruct[]): void;
  copyProgramMap(map: IOUStruct): void;
}

interface IState {
  items: IOUStruct[];
  height: number;
  width: number;
}

class Home extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      items: [{} as IOUStruct],
      height: window.innerHeight,
      width: window.innerWidth,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  public componentDidMount(): void {
    // this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.props.getOrgStructure(this.mapDrop);
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public render(): React.ReactNode {
    const { type } = this.props.selOrgUnit;
    return(
      <Stack style={{height: this.state.height - 81, width: this.state.width}}>
        <Stack horizontal={true} style={{height: '100%', width: '100%'}}>
          <StackItem styles={HomeContainerStyles}>
            <StackItem>
              <Label styles={headerStyles}>
                Organization structure
              </Label>
            </StackItem>
            <StackItem styles={{root: {marginLeft: '15px', marginRight: '15px'}}}>
              <TreeMenu 
                data={this.props.treeData} 
                disableKeyboard={false}
                hasSearch={false}
                debounceTime={125}
                onClickItem={(e) => this.onClickOrgUnit(e)}
                resetOpenNodesOnDataUpdate={false}
              />
            </StackItem>
          </StackItem>
          <StackItem styles={OrgContainerStyles}>
            {(type === 'node') &&
              <OrgDetails />
            }
            {(type === 'program') &&
              <ProgramDetails />
            }
            {(type === 'program root') &&
              <RootDetails rootType={type}/>
            }
            {(type === 'map') &&
              <MapDetails />
            }
            {(type === 'map root') &&
              <RootDetails rootType={type}/>
            }
            {(type === 'team') &&
              <ProgTeamDetails />
            }
            {(type === 'program map') &&
              <ProgramMapDetails />
            }
          </StackItem>
        </Stack>
        <StackItem styles={FooterStyles}>
          <OrgFooter />
        </StackItem>
      </Stack>
    )
  }
  private updateWindowDimensions(): void {
    this.setState({ width: window.innerWidth, height: (window.innerHeight) });
  }
  private onClickOrgUnit(e: Item): void {
    this.props.selectedOrgUnit(e);
  }

  mapDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    const obj = ev.currentTarget.attributes.getNamedItem("data");
    console.log(obj);
    if (obj) {
      const target = JSON.parse(obj.value);
      const source = ev.dataTransfer.getData("data");
      if (source) {
        const data = JSON.parse(source);
        console.log(data);
        if (target.parent === data.parent) {
          const name  = window.prompt('Program map name', '');
          if (name) {
            this.props.dragProgramMap(name, data.key, target.key);
            const progTeam = findDeep(this.props.treeData, target.key, undefined);
            if (progTeam) {
              const progMap = (progTeam.nodes as IOUStruct[]).slice(-1)[0];
              console.log(progMap);
              this.props.copyProgramMap(progMap);
            }
            this.props.saveOrgStructure(this.props.treeData);
          } else {
            window.alert('You can only add process maps from the current organization unit');
          }
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => ({
  treeData: state.orgStructure.treeData,
  selOrgUnit: state.orgStructure.selOrgUnit,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  selectedOrgUnit, getOrgStructure, dragProgramMap, saveOrgStructure, copyProgramMap
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Home)

const headerStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { fontSize: "20px", color: "blue", fontWeight: "normal", marginLeft:'13px' }
};

const HomeContainerStyles: IStackItemStyles = {
  root: {
    width: "75%",
    // height: "600px",
    borderStyle: "solid",
    borderWidth: "0px",
    borderColor: "#a0aeb2",
    marginTop: "13px",
    marginLeft: "1px",
    // marginRight: "-1px",
    marginBottom: "-1px",
    background: "",
    overflow: "auto",
  }
};
const OrgContainerStyles: IStackItemStyles = {
  root: {
    // display: "flex",
    width: "25%",
    // height: "600px",
    borderStyle: "solid",
    borderWidth: "0px",
    borderColor: "#a0aeb2",
    marginTop: "13px",
    // marginLeft: "13px",
    // marginRight: "-1px",
    marginBottom: "-1px",
    background: "",
    // overflow: "auto",
  }
};
export const FooterStyles = {
  root: {
    // height: "600px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dadce0",
    // marginRight: "-1px",
    marginBottom: "-1px",
    background: "#f0f5f9",
  }
};


